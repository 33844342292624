import Vue from "vue";
import VueRouter from "vue-router";
import Beranda from "../components/Beranda.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "beranda",
    component: Beranda,
    children: [
      {
        path: "/beranda",
        name: "Beranda",
        component: Beranda,
      },
    ],
  },
  {
    path: "/layanan",
    name: "Layanan",
    component: () => import("../components/Layanan.vue"),
    children: [
      {
        path: "/cek-premi",
        name: "Premi",
        component: () => import("../components/CekPremi.vue"),
      },
    ],
  },

  {
    path: "/karir",
    name: "Karir",
    component: () => import("../components/Karir.vue"),
  },
  {
    path: "/tentang",
    name: "Tentang",
    component: () => import("../components/Tentang.vue"),
  },
  {
    path: "/kontak",
    name: "Kontak",
    component: () => import("../components/Kontak.vue"),
  },
  {
    path: "/bengkel",
    name: "Bengkel",
    component: () => import("../components/Bengkel.vue"),
  },
  {
    path: "/kalkulasi",
    name: "Kalkulasi",
    component: () => import("../components/Kalkulasi.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return document
        .querySelector(to.hash)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      return savedPosition || { x: 0, y: 0 };
    }
  },
});

export default router;
