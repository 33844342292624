import Vue from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import VueMeta from 'vue-meta'

import AppHeader from './components/Header.vue'
import AppFooter from './components/Footer.vue'

Vue.config.productionTip = false
Vue.use(VueMeta)

Vue.component('app-header', AppHeader)
Vue.component('app-footer', AppFooter)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
