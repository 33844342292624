<template>
  <div class="bg-whiteBase">
    <!-- Start Beranda Karir -->
    <div
      class="text-center mx-4 mt-36 md:mx-10 md:mt-32 xl:grid xl:grid-cols-2 xl:mt-52"
    >
      <div class="">
        <img
          src="../assets/beranda/beranda-karir.webp"
          alt=""
          class="md:object-cover md:h-60 md:w-full xl:h-96 xl:-ml-10"
        />
      </div>
      <div class="2xl:ml-10">
        <h5
          class="tracking-widest text-lg mt-6 md:text-3xl md:mt-10 md:mb-5 xl:text-left xl:mt-0"
        >
          KARIR
        </h5>
        <h2
          class="text-2xl mt-2 md:text-4xl md:leading-snug xl:text-left xl:text-5xl 2xl:leading-tight"
        >
          Kami Memiliki Posisi <br />yang Tepat Untuk Anda
        </h2>
        <hr class="apply w-2/5 mx-auto mt-3 mb-6 md:w-1/6 xl:ml-0" />
        <p class="text-sm mx-6 md:hidden 2xl:text-left">
          Kami adalah orang-orang (tim) yang berpikiran sama yang memberikan
          jaminan dalam bidang asuransi, membantu meminimalisir resiko atas
          kerugian anda dan mewujudkannya.
        </p>
        <p
          class="hidden md:block md:text-base md:mx-2 xl:text-left 2xl:mr-40 xl:mr-20 xl:text-lg"
        >
          Kami adalah orang-orang (tim) yang berpikiran sama yang <br />
          memberikan jaminan dalam bidang asuransi, membantu meminimalisir
          <br />
          resiko atas kerugian anda dan mewujudkannya.
        </p>
        <div
          class="mx-14 mt-4 md:mx-60 xl:ml-0 2xl:mr-mtBerandaKarirButtonDekstop lg:mx-96"
        >
          <router-link to="/karir">
            <button
              type="button"
              class="mt-3 tracking-wide focus:outline-none font-semibold py-2 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown w-full mb-4"
            >
              APPLY NOW
            </button></router-link
          >
        </div>
      </div>
    </div>
    <!-- End Beranda Karir -->
  </div>
</template>

<script>
export default {
  name: "Apply",
};
</script>

<style>
hr.apply {
  border: 1.5px solid #c3986b!important;
  border: solid #c3986b;
  background-color: #c3986b;
}
.img1 {
  object-fit: cover;
  background-size: cover;
}
</style>