<template>
  <div class="bg-blackBlue relative">
    <div
      class="grid pt-12 px-6 md:px-1 grid-cols-2 xl:grid-cols-4 md:grid-cols-3 gap-4 xl:mx-28 xl:pt-16 md:pt-10 md:mx-10"
    >
      <div class="flex-1 col-span-2 md:col-span-1">
        <img
          src="../assets/logo/logo-footer.png"
          alt=""
          class="w-8/12 xl:w-full xl:-mt-8 2xl:w-7/12 md:w-full"
        />
        <p class="text-whiteBase mr-10 text-sm mt-4 md:-mr-7 leading-tight">
          PT. Troya Indo Perkasa adalah Perusahaan Konsultasi Asuransi yang
          berdiri sejak 28 Juli 2020. Perusahaan ini bertujuan memberikan solusi
          untuk konsultasi Asuransi Umum.
        </p>
        <div class="hidden xl:flex mt-7 items-center space-x-7">
          <a href="https://www.instagram.com/troyaindo.co.id/" target="_blank">
            <img
              src="../assets/ig.svg"
              alt=""
              class=""
              href="https://www.instagram.com/troyaindo.co.id/"
              target="_blank"
          /></a>
          <a href="https://twitter.com/IndoTroya" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-twitter text-white w-6" viewBox="0 0 16 16">
              <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
            </svg>            
          </a>
          <a href="https://www.facebook.com/profile.php?id=100069705125658" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-facebook text-white w-6" viewBox="0 0 16 16">
              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>           
          </a>
          <a href="https://vt.tiktok.com/ZSJVVR7UE/" target="_blank">
            <img src="@/assets/icon/tiktok.svg" alt="" class="w-6">
          </a>  
          <a href="https://www.youtube.com/channel/UC-uog1ftSPyM7uncttHGiyg" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-youtube text-white w-7" viewBox="0 0 16 16">
              <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
            </svg>
          </a>  
        </div>
        <div class="hidden xl:block mt-5">
          <a href="https://linktr.ee/TroyaIndoPerkasa" target="_blank">
            <img src="@/assets/icon/linktree.svg" alt="" class=" h-5"  >
          </a>   
        </div>
      </div>
      <div class="flex-1 pt-12">
        <p class="text-whiteBase text-base md:ml-32 md:pl-2 tracking-widest">
          PERUSAHAAN
        </p>
        <ul class="text-whiteBase mt-2 md:ml-32 md:pl-2 text-sm">
          <li class="mb-1"><router-link to="/beranda">Beranda</router-link></li>
          <li class="mb-1"><router-link to="/tentang">Tentang</router-link></li>
          <li class="mb-1"><router-link to="/karir">Karir</router-link></li>
          <li class="mb-1"><router-link to="/kontak">Kontak</router-link></li>
        </ul>
      </div>
      <div class="flex-1 pt-12">
        <p class="text-whiteBase text-base tracking-widest -ml-3 md:ml-14">
          PRODUK
        </p>
        <ul class="text-whiteBase -ml-3 mt-2 text-sm md:ml-14">
          <li class="mb-1"><router-link :to="{ name: 'Layanan', hash: '#benefit' }">Mengapa Memilih Kami?</router-link></li>
          <li class="mb-1"><router-link to="/layanan">Layanan Asuransi</router-link></li>
        </ul>
      </div>
      <div class="flex-1 col-span-2 pt-10">
        <p class="text-whiteBase text-base tracking-widest">INFO KAMI</p>
        <p class="text-whiteBase text-sm mt-3 md:-mr-20">
          Ruko ITC BSD Blok R No.2, Jl. Pahlawan Seribu
        </p>
        <p class="text-whiteBase text-sm mr-40 md:-mr-32">
          BSD City Tangerang Selatan, Banten-Indonesia (15322)
        </p>
        <p class="text-whiteBase text-sm md:mt-3 mt-4">admin@troyaindo.co.id</p>
        <p class="text-whiteBase text-base md:mt-3 mt-4">+62 81299556161</p>
        <div class="xl:hidden flex mt-7 items-center space-x-7">
          <a href="https://www.instagram.com/troyaindo.co.id/" target="_blank">
            <img
              src="../assets/ig.svg"
              alt=""
              class=""
              href="https://www.instagram.com/troyaindo.co.id/"
              target="_blank"
          /></a>
          <a href="https://twitter.com/IndoTroya" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-twitter text-white w-6" viewBox="0 0 16 16">
              <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
            </svg>            
          </a>
          <a href="https://www.facebook.com/profile.php?id=100069705125658" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-facebook text-white w-6" viewBox="0 0 16 16">
              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>           
          </a>
          <a href="https://vt.tiktok.com/ZSJVVR7UE/" target="_blank">
            <img src="@/assets/icon/tiktok.svg" alt="" class="w-6">
          </a>  
                    <a href="https://www.youtube.com/channel/UC-uog1ftSPyM7uncttHGiyg" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-youtube text-white w-7" viewBox="0 0 16 16">
              <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
            </svg>
          </a>  
        </div>
        <div class="xl:hidden block mt-5">
          <a href="https://linktr.ee/TroyaIndoPerkasa" target="_blank">
            <img src="@/assets/icon/linktree.svg" alt="" class=" h-5"  >
          </a>   
        </div>
      </div>
      <div
        class="flex-1 pt-10 md:-ml-24 lg:-ml-44 col-span-2 md:col-span-1 xl:col-start-3 xl:col-span-4 xl:ml-64 2xl:ml-96 xl:pl-6 md:pl-2 xl:-mt-12"
      >
        <div class="grid grid-cols-2 gap-6">
          <div>
            <img
              src="../assets/footer-ojk.webp"
              alt=""
              class="w-11/12 md:w-full"
            />
            <p class="text-whiteBase text-sm -mr-8 md:-mr-14 mt-3">
              Terdaftar dan diawasi OJK
            </p>
            <p
              class="mt-1 font-semibold text-whiteBase text-sm tracking-widest -mr-8"
            >
              S-1259/NB.122/2020
            </p>
          </div>
          <img
            src="../assets/footer-mari.webp"
            alt=""
            class="md:-mt-3 ml-2 w-7/12 md:w-9/12"
          />
        </div>
      </div>
    </div>
    <div
      class="flex flex-nowrap mt-6 md:mt-4 xl:mt-0 justify-end xl:justify-start mr-4 md:mr-10 lg:justify-end xl:mr-9 xl:ml-28 cursor-pointer"
      @click="scrollToTop"
    >
      <p class="text-whiteBase text-sm tracking-widest">Back to</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="currentColor"
        class="bi bi-arrow-up text-whiteBase"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
        />
      </svg>
      <p class="text-whiteBase tracking-widest text-sm">Top</p>
    </div>

    <div class="mx-4 md:mx-8 xl:mx-16 mt-3 border-t border-whiteBase py-2">
      <p class="hidden md:block text-whiteBase text-center text-xs">
        ©2020 troyaindoperkasa. All rights reserved | terms of Service | Privacy
        Policy
      </p>
      <p class="text-whiteBase md:hidden text-center text-xs mx-8">
        ©2020 troyaindoperkasa. All rights reserved terms of Service | Privacy
        Policy
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
</style>