<template>
  <div id="app">
    <app-header></app-header>
    <router-view> </router-view>
    <app-footer></app-footer>
    <div class="absolute align-middle bottom-0 right-0 wa-css ">
      <a href="https://wa.me/6281299556161" target="_blank">
        <img
          src="@/assets/wa.svg"
          alt=""
          class="fixed z-40 right-0 object-right p-3 -mt-36 -mr-1 md:p-8 md:-mr-4 md:-mt-48 lg:p-14 lg:-mr-9 lg:-mt-52 xl:p-iconWa xl:-mt-56 xl:-mr-16 2xl:-mt-72 2xl:p-32 2xl:-mr-28"
      /></a>
      <svg
        v-show="visible"
        id="backToTopID"
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        fill="currentColor"
        class="bi bi-arrow-up-circle-fill text-blackBlue fixed z-50 right-0 object-right -mt-16 mr-2 px-1 md:px-0 md:mr-4 md:-mt-20 lg:mr-5 lg:px-1 xl:-mt-16 xl:p-1 xl:mr-6 2xl:-mt-24 2xl:mr-4 cursor-pointer"
        viewBox="0 0 16 16"
        @click="scrollToTop"
      >
        <path
          d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "PT. Troya Indo Perkasa ",
    titleTemplate: "%s | Solusi Untuk Konsultasi Asuransi Umum.",
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    // getIcon(event) {
    //   console.log(event)
    //   if (event.path[1].pageYOffset < 100) {
    //     this.visible = false;
    //   } else {
    //     this.visible = true;
    //   }
    // },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  // created() {
  //   window.addEventListener("scroll", this.getIcon);
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.getIcon);
  // },  
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
/* Neuemontreal */
@font-face {
  font-family: "NeueMontreal-Regular";
  src: local("neue-montreal"),
    url(./fonts/neue-montreal/NeueMontreal-Regular.otf) format("truetype");
}
/* Proxima-nova */
@font-face {
  font-family: "Proxima-Extrabold";
  src: local("proxima-nova"),
    url(./fonts/proxima-nova/Proxima-Extrabold.otf) format("truetype");
}
@font-face {
  font-family: "Proxima-Regular";
  src: local("proxima-nova"),
    url(./fonts/proxima-nova/Proxima-Regular.otf) format("truetype");
}
/* .wa-css {
  z-index: 999999999;
} */
</style>