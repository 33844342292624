<template>
  <nav class="mx-0 bg-whiteBase">
    <div class="md:mx-10 xl:mx-16 2xl:mx-28 text-center md:text-left">
      <div class="flex justify-between mb-1">
        <div class="flex-1 lg:-mr-10">
          <p
            class="hidden 2xl:text-sm lg:block text-xs mt-2 mb-1 tracking-wide -mr-40 text-blackBlue"
          >
            Ruko ITC BSD Blok R No.2, Jl. Pahlawan Seribu BSD City - Tangerang
            Selatan (15322) | admin@troyaindo.co.id
          </p>
          <p
            class="hidden text-xs mt-2 md:block lg:hidden -mr-60 text-blackBlue"
          >
            Ruko ITC BSD Blok R No.2 Jl.Pahlawan Seribu. BSD City Tangerang
            Selatan | admin@troyaindo.co.id
          </p>
          <p class="text-xs mt-2 md:hidden text-blackBlue">
            Ruko ITC BSD Blok R No.2, Jl. Pahlawan Seribu BSD City (15322)
          </p>

          <p class="text-sm my-2 md:hidden text-blackBlue">
            admin@troyaindo.co.id
          </p>
        </div>
        <div class="flex-1 cursor-pointer hidden md:block lg:hidden">
          <!-- <div class="flex justify-end">
            <div class="flex-initial">
              <img src="../assets/user.svg" alt="" class="pt-2 pb-1" />
            </div>
            <div class="flex-initial cursor-pointer">
              <a href="http://app.troyaindo.co.id/">
                <p class="mt-2 ml-2 text-xs text-blackBlue">Masuk</p></a
              >
            </div>
          </div> -->
        </div>
        <div class="hidden lg:block flex-1 text-right">
          <div class="grid lg:grid-cols-6 2xl:grid-cols-8 gap-4">
            <div class="lg:col-span-5 2xl:col-span-7">
              <p class="text-xs 2xl:text-sm mt-2 tracking-wide text-blackBlue">
                <span class="font-semibold">Hubungi Kami</span> : +62 81299556161
              </p>
            </div>
            <!-- <div class="flex justify-end cursor-pointer">
              <div class="flex-initial ...">
                <img src="../assets/user.svg" alt="" class="pt-2 w-full" />
              </div>
              <div class="flex-initial cursor-pointer">
                <a href="http://app.troyaindo.co.id/">
                  <p class="mt-2 ml-2 text-xs text-blackBlue 2xl:text-sm">
                    <span class="font-semibold">Masuk</span>
                  </p></a
                >
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <hr class="border-lightCream" />
    <div class="hidden md:block">
      <div class="flex justify-between md:mx-10 xl:mx-16 2xl:mx-28">
        <div>
          <router-link to="/beranda"
            ><img
              src="../assets/logo/logo.png"
              alt=""
              class="w-7/12 my-4 2xl:w-9/12 xl:w-9/12"
          /></router-link>
        </div>
        <div
          class="flex flex-wrap gap-8 justify-center my-auto md:-ml-20 2xl:mx-0"
        >
          <router-link to="/beranda"
            ><p
              class="text-sm font-semibold 2xl:text-base focus:border-b-2 focus:border-brownCLight py-1"
            >
              Beranda
            </p></router-link
          >
          <router-link to="/layanan"
            ><p
              class="text-sm font-semibold 2xl:text-base py-1 focus:border-b-2 focus:border-brownCLight"
            >
              Layanan
            </p></router-link
          >
          <router-link to="/karir"
            ><p
              class="text-sm font-semibold 2xl:text-base py-1 focus:border-b-2 focus:border-brownCLight"
            >
              Karir
            </p></router-link
          >
          <router-link to="/tentang"
            ><p
              class="text-sm font-semibold 2xl:text-base py-1 focus:border-b-2 focus:border-brownCLight"
            >
              Tentang
            </p></router-link
          >
          <router-link to="/kontak"
            ><p
              class="text-sm font-semibold 2xl:text-base py-1 focus:border-b-2 focus:border-brownCLight"
            >
              Kontak
            </p></router-link
          >
          <router-link to="/bengkel"
            ><p
              class="text-sm font-semibold 2xl:text-base py-1 focus:border-b-2 focus:border-brownCLight"
            >
              Bengkel
            </p></router-link
          >        
          <router-link to="/kalkulasi"
            ><p
              class="text-sm font-semibold 2xl:text-base py-1 focus:border-b-2 focus:border-brownCLight"
            >
              Kalkulasi
            </p></router-link
          >    
        </div>
      </div>
    </div>
    <div class="md:hidden -mt-4">
      <button @click="drawer" class="outline-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          class="bi bi-list absolute my-5 ml-2 text-brownC"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </button>
      <img src="../assets/logo/logo.png" alt="" class="mx-auto w-7/12" />
    </div>

    <transition
      enter-class="opacity-0"
      enter-active-class="ease-out transition-medium"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-active-class="ease-out transition-medium"
      leave-to-class="opacity-0"
    >
      <div
        @keydown.esc="isOpen = false"
        v-show="isOpen"
        class="z-10 fixed inset-0 transition-opacity"
      >
        <div
          @click="isOpen = false"
          class="absolute inset-0 bg-black opacity-50"
          tabindex="0"
        ></div>
      </div>
    </transition>
    <aside
      class="transform top-0 left-0 w-64 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30"
      :class="isOpen ? 'translate-x-0' : '-translate-x-full'"
    >
      <svg
        @click="drawer"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        class="bi bi-x ml-auto m-2"
        viewBox="0 0 16 16"
      >
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
        />
      </svg>
      <p class="p-4 text-sm border-b border-grey-200 ml-1 -mt-4">
        Hubungi Kami : +62 81299556161
      </p>
      <!-- <div class="flex border-b border-grey-200">
        <div class="flex-initial my-2 ml-8">
          <img src="../assets/user.svg" alt="" class="pt-2 pb-1" />
        </div>
        <div class="flex-initial cursor-pointer my-2">
          <a href="http://app.troyaindo.co.id/">
            <p class="mt-1 ml-2 text-base text-blackBlue pl-3 mb-2">Masuk</p></a
          >
        </div>
      </div> -->
      <span @click="isOpen = false" class="flex items-center px-4 pt-4"
        ><span class="mr-2"> </span>
        <router-link to="/beranda"
          ><p class="text-sm active:border-b-2 active:border-brownCLight py-1">
            Beranda
          </p></router-link
        ></span
      >
      <span @click="isOpen = false" class="flex items-center px-4 pt-4"
        ><span class="mr-2"> </span>
        <router-link to="/layanan"
          ><p class="text-sm active:border-b-2 active:border-brownCLight py-1">
            Layanan
          </p></router-link
        ></span
      >
      <span @click="isOpen = false" class="flex items-center px-4 pt-4"
        ><span class="mr-2"> </span>
        <router-link to="/karir"
          ><p class="text-sm active:border-b-2 active:border-brownCLight py-1">
            Karir
          </p></router-link
        ></span
      >
      <span @click="isOpen = false" class="flex items-center px-4 pt-4"
        ><span class="mr-2"> </span>
        <router-link to="/tentang"
          ><p class="text-sm active:border-b-2 active:border-brownCLight py-1">
            Tentang
          </p></router-link
        ></span
      >
      <span @click="isOpen = false" class="flex items-center px-4 pt-4"
        ><span class="mr-2"> </span>
        <router-link to="/kontak"
          ><p class="text-sm active:border-b-2 active:border-brownCLight py-1">
            Kontak
          </p></router-link
        ></span
      >
    </aside>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    drawer() {
      this.isOpen = !this.isOpen;
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (process.client) {
          if (isOpen) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      },
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27 && this.isOpen) this.isOpen = false;
    });
  },
};
</script>

<style>
.router-link-active,
.router-link-exact-active {
  cursor: pointer;
  border-bottom: 2px solid #c8a385;
}
</style>