<template>
  <div class="bg-whiteBase">
    <!-- <div class="absolute align-middle bottom-0 right-0">
      <a href="https://wa.me/6281299556161" target="_blank">
        <img
          src="../assets/wa.svg"
          alt=""
          class="fixed z-40 right-0 object-right p-3 -mt-36 -mr-1 md:p-8 md:-mr-4 md:-mt-48 lg:p-14 lg:-mr-9 lg:-mt-52 xl:p-iconWa xl:-mt-56 xl:-mr-16 2xl:-mt-72 2xl:p-32 2xl:-mr-28"
      /></a>
      <svg
        v-show="visible"
        id="backToTopID"
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        fill="currentColor"
        class="bi bi-arrow-up-circle-fill text-blackBlue fixed z-50 right-0 object-right -mt-16 mr-2 px-1 md:px-0 md:mr-4 md:-mt-20 lg:mr-5 lg:px-1 xl:-mt-16 xl:p-1 xl:mr-6 2xl:-mt-24 2xl:mr-4 cursor-pointer"
        viewBox="0 0 16 16"
        @click="scrollToTop"
      >
        <path
          d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
        />
      </svg>
    </div> -->

    <!-- Main Banner -->
    <div :style="slide1" class="flex slider-cus bg-right-top bg-contain bg-no-repeat  text-center space-x-10 mt-16 ">
      
      <!-- wrappper -->
      <div class=" grid grid-cols-1 md:grid-cols-2 md:mt-5 mx-10 md:mx-10  lg:mx-20 2xl:mx-28 gap-20">

        <div class=" text-center md:text-left self-center">
          <div class=" self-center">
            <h1 class="text-3xl md:text-5xl xl:text-6xl  my-5 tracking-wide">
              ASURANSI UNTUK SEMUA KEBUTUHAN ANDA
            </h1>
            <p class="text-sm md:text-base 2xl:text-lg my-5 ">
              PT. Troya Indo Perkasa adalah Perusahaan Konsultasi Asuransi yang bertujuan memberikan solusi untuk konsultan Asuransi Umum.
            </p>
            <div class=" mt-10">
              <router-link to="/tentang">
                <button
                  type="button"
                  class="tracking-wide focus:outline-none font-semibold py-2 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown"
                >
                  PELAJARI LEBIH LANJUT
                </button></router-link
              >
              <router-link to="/layanan/cek-premi">
                <button
                  type="button"
                  class="hidden tracking-widest focus:outline-none font-semibold py-2 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown"
                >
                  CEK PREMI SEKARANG
                </button></router-link
              >
            </div>
          </div>
        </div>

        <div class=" flex items-center justify-center">
          <vue-agile  :options="options" class="vue-portofolio" >
            <div v-for="(value, idx) in content" :key="idx" class="service">
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <img :src="value.img" alt="" class=" w-80 lg:w-auto">
                <div class=" flex flex-col md:text-left justify-center items-center md:items-start text-center">
                  <h3 class="text-2xl uppercase tracking-wide mb-1">{{value.title}}</h3>
                  <div class="mb-5">{{value.text}}</div>
                  <div>
                     <router-link to="/layanan" class=" uppercase text-brownA border-brownA py-1.5 px-3 border inline-block font-medium">Klik Disini</router-link>
                  </div>
                </div>
              </div>
            </div>
          </vue-agile>  
        </div>

      </div>
    </div>
    <!-- Main Banner -->
          <!-- <img src="@/assets/beranda/slide1.webp" alt="" class="w-4/5"> -->
    <!-- Start Beranda Layanan -->
    <div
      class="text-center mx-6 md:text-left 2xl:mx-24 mt-72 md:mt-28"
    >
      <h2
        class="text-2xl mx-12 tracking-wider md:pt-20 md:text-4xl md:ml-4 md:mr-80 md:leading-snug 2xl:mr-mrBerandaLayananTitle2xl 2xl:pt-24 lg:mr-96 lg:pr-36 lg:mx-6 2xl:text-5xl 2xl:leading-snug xl:text-5xl xl:mr-96 xl:pr-44 xl:ml-16 xl:leading-tight 2xl:ml-5"
      >
        Layanan Kami Menjamin Atas Kerugian Anda
      </h2>
      <hr
        class="beranda border w-2/5 mx-auto mt-3 mb-6 md:mr-auto md:ml-4 md:w-1/6 2xl:border-8 lg:mx-6 2xl:w-1/12 xl:mx-16 2xl:mx-5"
      />
      <p
        class="text-sm mb-8 mx-8 md:text-base md:ml-4 md:mt-8 md:mr-48 2xl:mr-mrBerandaLayananTitle2xl 2xl:pr-32 lg:mr-96 lg:pr-20 lg:mx-6 2xl:text-lg xl:ml-16 2xl:ml-5 xl:text-lg xl:mr-mtBerandaKarirButtonDekstop xl:pr-32"
      >
        Kami dapat menjamin semua kerugian atau kerusakan yang anda alami dan
        pastinya kami selalu memberikan layanan terbaik
      </p>
      <div
        class="md:grid md:grid-cols-2 md:gap-10 md:mx-6 2xl:grid-cols-3 2xl:gap-10 lg:grid-cols-3 xl:mx-16 2xl:mx-0"
      >


        <div class="text-left bg-whiteBase shadow-md px-10 py-6 my-6 md:my-1 2xl:px-20 2xl:mx-5 2xl:py-10 order-2">
          <router-link to="/layanan"
          >
            <img src="../assets/layanan/asuransi-mobil.svg" alt="" />
            <h2 class="text-2xl my-4 2xl:text-2xl">Asuransi Mobil</h2>
            <p class="text-sm mr-16 lg:mr-0 2xl:text-lg">
              Menjamin pembayaran ganti rugi atas kerusakan mobil anda.
            </p>
          </router-link>
        </div>


        <div class="text-left bg-whiteBase shadow-md px-10 py-6 my-6 md:my-1 2xl:px-20 2xl:mx-5 order-3">
          <router-link to="/layanan"
            
          >
            <img src="../assets/layanan/asuransi-motor.svg" alt="" />
            <h2 class="text-2xl my-4 2xl:text-2xl">Asuransi Motor</h2>
            <p class="text-sm mr-16 lg:mr-0 2xl:text-lg">
              Menjamin pembayaran ganti rugi atas kerusakan motor anda.
            </p>
          </router-link>
        </div>


        <div class="text-left bg-whiteBase shadow-md px-10 py-6 my-6 md:my-1 2xl:px-20 2xl:mx-5 order-1">
          <router-link to="/layanan">
            <img src="../assets/layanan/asuransi-properti.svg" alt="" />
            <h2 class="text-2xl my-4 2xl:text-2xl">Asuransi Properti</h2>
            <p class="text-sm mr-16 lg:mr-0 2xl:text-lg">
              Menjamin pembayaran ganti rugi atas kerugian properti anda.
            </p>
          </router-link>
        </div>


        <div class="text-left bg-whiteBase shadow-md px-10 py-6 my-6 md:my-1 2xl:px-20 2xl:mx-5 relative order-5">
          <router-link to="/layanan"
          >
            <img src="../assets/layanan/asuransi-kesehatan.svg" alt="" />
            <h2 class="text-2xl my-4 2xl:text-2xl">Asuransi Kesehatan</h2>
            <p class="text-sm mr-16 lg:mr-0 2xl:text-lg">
              Menjamin pembayaran ganti rugi atas kesehatan anda.
            </p>
          </router-link>
        </div>


      <div class="text-left bg-whiteBase shadow-md px-10 py-6 my-6 md:my-1 2xl:px-20 2xl:mx-5 relative order-4">
                <router-link to="/layanan"
        >
          <img src="../assets/layanan/asuransi-barang.svg" alt="" />
          <h2 class="text-xl my-4 2xl:text-2xl">
            Asuransi Pengangkutan Barang
          </h2>
          <p class="text-sm mr-16 lg:mr-0 2xl:text-lg">
            Menjamin atas resiko pengangkutan barang yang diasuransikan.
          </p>
        </router-link>
      </div>


      <!-- <div class="text-left bg-whiteBase shadow-md px-10 py-6 my-6 md:my-1 2xl:px-20 2xl:mx-5 relative order-7">
        <router-link to="/layanan"
        >
          <img src="../assets/layanan/asuransi-elektronik.svg" alt="" />
          <h2 class="text-xl my-4 2xl:text-2xl">
            Asuransi Perangkat Elektronik
          </h2>
          <p class="text-sm mr-16 lg:mr-0 2xl:text-lg">
            Menjamin pembayaran ganti rugi atas kerusakan suatu perangkat.
          </p>
        </router-link>
      </div> -->


      <div  class="text-left bg-whiteBase shadow-md px-10 py-6 my-6 md:my-1 2xl:px-20 2xl:mx-5 relative order-6">
        <router-link to="/layanan"
           
          >
            <img src="../assets/layanan/asuransi-surety.svg" alt="" />
            <h2 class="text-xl my-4 2xl:text-2xl">Asuransi Surety Bond</h2>
            <p class="text-sm mr-16 lg:mr-0 2xl:text-lg">
              Menjamin terjadinya wanprestasi antara Pemilik Pekerjaan dengan
              Pelaksana Pekerjaan, yang sering timbul pada pekerjaan baik
              konstruksi maupun non kontruksi.
            </p>
          </router-link>
      </div>


    <div class="text-left bg-whiteBase shadow-md px-10 py-6 my-6 md:my-1 2xl:px-20 2xl:mx-5 relative order-3">
        <router-link to="/layanan"
          
        >
          <img src="../assets/layanan/asuransi-alatberat.svg" alt="" />
          <h2 class="text-xl my-4 2xl:text-2xl">Asuransi Heavy Equipment</h2>
          <p class="text-sm mr-16 lg:mr-0 2xl:text-lg">
            Menjamin terjadinya kerusakan yang biasa terjadi dalam sebuah proyek
            terhadap alat-alat berat baik pada saat digunakan, maupun sedang
            dibongkar.
          </p>
        </router-link>
    </div>


      </div>
    </div>
    <img
      src="../assets/background/bg-bottom.webp"
      alt=""
      class="-mt-40 md:-mt-72 2xl:mt-mtBerandaLayananBottomDekstop lg:-mt-96 xl:mt-mtBerandaLayananLg"
    />
    <!-- End Beranda Layanan -->

    <!-- Start Beranda Mitra -->
    <div class="mt-10 mx-6 text-center md:mx-20 md:mt-0 2xl:-mt-40 xl:-mt-32">
      <h2 class="text-2xl md:hidden">
        Kami bermitra dengan <br />
        Perusahaan Terbaik di Indonesia
      </h2>
      <h2
        class="hidden md:block md:text-4xl md:mx-16 md:leading-snug lg:mx-48 2xl:text-5xl"
      >
        Kami bermitra dengan Perusahaan Terbaik di Indonesia
      </h2>
      <hr
        class="beranda border-2 w-2/5 mx-auto mt-3 mb-6 md:w-1/6 2xl:relative 2xl:z-50 2xl:w-1/12 2xl:border-8 xl:relative"
      />
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 w-4/5 mx-auto mt-10 lg:mt-14 xl:mt-20 gap-10">

            <div class=" h-24 lg:h-28 mx-auto">
              <img src="../assets/beranda/abi.webp" alt="" class=" h-full object-contain"/>
            </div>            
            <div class=" h-24 lg:h-28 mx-auto">
              <img src="../assets/beranda/aca.webp" alt="" class=" h-full object-contain" />
            </div>
            <div class=" h-24 lg:h-28 mx-auto">
              <img src="../assets/beranda/reliance.webp" alt="" class="h-full object-contain"/>
            </div>            
            <div class=" h-24 lg:h-28 mx-auto">
              <img src="../assets/beranda/sahabat.webp" alt="" class=" h-full object-contain"/>
            </div>
            <div class=" h-24 lg:h-28 mx-auto">
              <img src="../assets/beranda/zurich.png" alt="" class=" h-full object-contain"/>
            </div>

      </div>
    </div>
    <!-- End Beranda Mitra -->

    <!-- Start Beranda Karir -->
    <Apply />
    <!-- End Beranda Karir -->

    <!-- Start Beranda Bottom -->
    <div class="my-14 mt-20 bg-lightCream py-10 md:mt-28">
      <img
        src="../assets/logo/logo.png"
        alt=""
        class="w-10/12 mx-auto md:w-8/12 md:mt-10 2xl:w-3/12 lg:w-5/12 xl:w-4/12"
      />
      <img
        src="../assets/background/bg-bottom-left.webp"
        alt=""
        class="hidden md:block md:-ml-80 md:pl-60 md:-mt-64 2xl:-mt-60 2xl:-ml-0 2xl:pl-0 2xl:w-4/12 xl:w-4/12 lg:pl-0 lg:w-5/12 lg:-mt-52 lg:ml-0"
      />
      <p
        class="text-sm mx-11 text-justify mt-4 md:text-base md:mx-10 md:-mt-72 2xl:text-lg 2xl:mx-80 xl:mx-48 2xl:mt-mtBerandaLayananBottomDekstop 2xl:pt-80 lg:mt-mtBerandaBottomLG lg:pt-96 lg:mx-20"
      >
        PT. Troya Indo Perkasa adalah Perusahaan Konsultasi Asuransi yang
        berdiri sejak 28 Juli 2020. Perusahaan ini bertujuan memberikan solusi
        untuk konsultasi Asuransi Umum. Selain itu kami memberikan layanan
        Asuransi Umum seperti, Asuransi Kendaraan, Asuransi Properti, Asuransi
        Kesehatan, dan Asuransi Pengangkutan Barang
      </p>
      <p
        class="text-sm mx-11 text-justify mt-4 md:text-base md:mx-10 md:mt-8 2xl:mx-80 lg:mx-20 2xl:text-lg 2xl:mt-14 xl:mx-48"
      >
        PT. Troya Indo Perkasa saat ini berlokasi di Ruko ITC BSD Blok R No.2
        Jl.Pahlawan Seribu. BSD City Tangerang Selatan - Banten (15322) .
      </p>
      <img
        src="../assets/background/bg-bottom-right.webp"
        alt=""
        class="hidden md:block md:pl-60 md:-mt-96 md:ml-0 opacity-50 xl:w-4/12 xl:ml-mtBerandaLayananDekstop 2xl:mt-mtBerandaButtonBottomDekstop 2xl:pl-0 2xl:opacity-100 2xl:ml-mlBerandabgbottomDekstop lg:pl-0 lg:w-5/12 lg:ml-mlBerandaBottomRightLG"
      />
      <div
        class="mx-28 text-center mt-14 md:mx-60 md:block md:-mt-28 2xl:-mt-32 2xl:mx-mtBerandaButtonDekstop lg:mx-96 lg:-mt-10"
      >
        <router-link to="/tentang">
          <button
            type="button"
            class="tracking-widest focus:outline-none font-semibold py-2 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown w-full mb-10 2xl:mb-10 lg:mb-0 mx-auto xl:w-5/12"
          >
            TENTANG KAMI
          </button></router-link
        >
      </div>
    </div>
    <!-- End Beranda Bottom -->
  </div>
</template>

<script>
import Apply from "@/components/Apply.vue";
import {VueAgile} from "vue-agile"

export default {
  name: "Beranda",
  components: {
    Apply,
    VueAgile
  },
  data() {
    return {
      visible: false,
      slide1: {
            backgroundImage: `url(${require("@/assets/background/bg-top.webp")})`
      },
      options: {
            slidesToShow: 1,
            infinite: true,
            centerMode: false,
            navButtons: false,
            autoplay: true,
            autoplaySpeed: 6000
            // responsive: [
            //         {
            //             breakpoint: 640,
            //             settings: {
            //             slidesToShow: 2,
            //             },
            //         },
            //         {
            //             breakpoint: 1024,
            //             settings: {
            //             slidesToShow: 2,
            //             },
            //         },
            //         {
            //             breakpoint: 1280,
            //             settings: {
            //             slidesToShow: 3,
            //             },
            //         },
            //     ],
      },
      content: [
        {
          img:`${require("@/assets/slide/kendaraan.webp")}`,
          title: 'Asuransi Kendaraan',
          text:'Kendaraan kalian, Pilih Asuransi dengan bijak, dan tentunya terjamin'
        },
        {
          img:`${require("@/assets/slide/property.webp")}`,
          title: 'Asuransi Properti',
          text:'Properti kalian, Pilih Asuransi dengan bijak, dan tentunya terjamin'
        },
        {
          img:`${require("@/assets/slide/alat-berat.webp")}`,
          title: 'Asuransi Alat Berat',
          text:'Menjamin alat berat, Pilih Asuransi dengan bijak, dan tentunya terjamin'
        },
        {
          img:`${require("@/assets/slide/pengangkutan-barang.webp")}`,
          title: 'Asuransi Pengangkutan Barang',
          text:'Menjamin pengangkutan barang, Pilih Asuransi dengan bijak, dan tentunya terjamin'
        },
        {
          img:`${require("@/assets/slide/kesehatan.webp")}`,
          title: 'Asuransi Kesehatan',
          text:'Menjamin kesehatan kalian, Pilih Asuransi dengan bijak, dan tentunya terjamin'
        },
        {
          img:`${require("@/assets/slide/surety-bond.webp")}`,
          title: 'Asuransi Surety Bond',
          text:'Menjamin pembayaran kepada Obligee, Pilih Asuransi dengan bijak, dan tentunya terjamin'
        },
        // {
        //   img:`${require("@/assets/slide/elektronik.webp")}`,
        //   title: 'Asuransi Perangkat Elektronik',
        //   text:'Menjamin kerusakan perangkat elektronik, Pilih Asuransi dengan bijak, dan tentunya terjamin'
        // },
      ]
    };
  },
};
</script>

<style>
hr.beranda {
  border: solid #c3986b;
  background-color: #c3986b;
}
hr.klaim {
  border: 1.5px solid #c3986b;
  background-color: #c3986b;
}
.img1 {
  object-fit: cover;
  background-size: cover;
}
.slider-cus {
  height: 600px;
}
@media only screen and (min-width: 1536px) {
  .slider-cus {
  height: 800px;
}
}
</style>


<style>
      .vue-portofolio {
        margin: 0 -20px;
        width: calc(100% + 40px);
    }
    .vue-portofolio     .service {
        padding: 0 40px;
    }
    .vue-portofolio .agile__nav-button {
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    bottom:35%;
    }
    .vue-portofolio .agile__nav-button--prev {
    left: -20px;
    }
    .vue-portofolio .agile__nav-button--next {
    right: -20px;
    }
    @media only screen and (max-width: 1024px) {
        .vue-portofolio .agile__nav-button {
        position: absolute;
        top: -20px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        bottom:auto;
        }
        .vue-portofolio .agile__nav-button--prev {
        left: 40px;
        }
        .vue-portofolio .agile__nav-button--next {
        right: 40px;
        }
    }

</style>